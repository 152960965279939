import { FC } from "react";
import { colors } from "../../../../styles/utils/vars";
import { CategoryItemPropsType, ICategoryTreeItem } from "../../../../types/types";
import { ROUTES } from "../../../../utils/constants";
import { Icon } from "../../../ui/Icon";
import { Link } from "../../../ui/Link";
import { cssAngle, StyledCategoryLink, StyledCategoryName, StyledCategoryQty, StyledCategoryTitle } from "./StyledCategory";
export const Title: FC<{
  updateCurrentId: () => void;
  parent?: number;
  category: ICategoryTreeItem;
  isChildrenContains: boolean;
  path: string;
} & Omit<CategoryItemPropsType, "setCurrentId">> = ({
  updateCurrentId,
  parent,
  category,
  isChildrenContains,
  currentId,
  path
}) => {
  let variantView: "main" | "mainDrop" | "child" = "child";
  if (parent === 0) {
    variantView = "main";
    if (isChildrenContains) {
      variantView = "mainDrop";
    }
  } else {
    variantView = "child";
  }
  return <StyledCategoryTitle data-variant={variantView} onClick={() => {
    if (variantView !== "mainDrop" || currentId === category.uuid) {
      return;
    }
    updateCurrentId();
  }}>
      {variantView === "main" && <StyledCategoryLink>
          <Link href={`${ROUTES.catalog}/${path}`} scroll>
            {category.name}
          </Link>
        </StyledCategoryLink>}
      {variantView === "mainDrop" && <>
          <StyledCategoryLink>
            <Link href={`${ROUTES.catalog}/${path}`} scroll>
              {category.name}
            </Link>
          </StyledCategoryLink>
          <StyledCategoryName>{category.name}</StyledCategoryName>
          {currentId !== category.uuid && <Icon NameIcon={"AngleRight"} fill={colors.brand.yellow} className={cssAngle} />}
        </>}
      {variantView === "child" && <StyledCategoryLink>
          <Link href={`${ROUTES.catalog}/${path}`} scroll>
            <StyledCategoryName>
              {category.name}{" "}
              <StyledCategoryQty>{category.product_qty}</StyledCategoryQty>
            </StyledCategoryName>
          </Link>
        </StyledCategoryLink>}
    </StyledCategoryTitle>;
};