import { forwardRef, memo } from "react";
import { Category } from "./Category/Category";
import { cssIsSelectedCategory } from "./Category/StyledCategory";
import { CategoriesInner, createCategoriesComponent } from "./StyledCategories";
import { ICategoryTreeItem, UpdateCurrentCategoryPopupIdType } from "@/types";
export type VariantCategoriesType = "catalog" | "popup";
export type CategoriesPropsType = {
  categories: ICategoryTreeItem[];
  variant?: VariantCategoriesType;
  currentCategoryId?: null | string;
  updateCurrentCategoryId?: UpdateCurrentCategoryPopupIdType;
};
const Categories = memo(forwardRef<HTMLDivElement, CategoriesPropsType>(({
  categories,
  variant = "popup",
  currentCategoryId = null,
  updateCurrentCategoryId
}, ref) => {
  const CategoriesContainerComponent = createCategoriesComponent(variant);
  return <>
          {categories.length > 0 && <CategoriesContainerComponent ref={ref}>
              <CategoriesInner className={currentCategoryId !== null ? cssIsSelectedCategory : ""}>
                {categories.map((category, index) => <Category category={category} key={index} updateCurrentCategoryId={updateCurrentCategoryId} currentId={currentCategoryId} />)}
              </CategoriesInner>
            </CategoriesContainerComponent>}
        </>;
}));
Categories.displayName = "Categories";
export default Categories;