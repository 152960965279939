import { FC, memo } from "react"
import { StyledList } from "../../../List/StyledList"
import { Category } from "./Category"
import { CategoryItemPropsType, ICategoryTreeItem } from "@/types"

export type SubCategoryPropsType = CategoryItemPropsType & {
  categories: ICategoryTreeItem[]
}

export const SubCategory: FC<SubCategoryPropsType> = memo(
  ({ currentId, updateCurrentCategoryId, categories }) => {
    if (!categories.length) {
      return null
    }
    return (
      <StyledList as={"div"}>
        {categories.map((category) => {
          return (
            <Category
              key={category.uuid || ""}
              category={category}
              currentId={currentId}
              updateCurrentCategoryId={updateCurrentCategoryId}
            />
          )
        })}
      </StyledList>
    )
  },
)

SubCategory.displayName = "SubCategory"
