import React, { FC, HTMLAttributes, memo } from "react";
import BANYA from "./images/02efe185cecd8513a5a84d82238cf506.jpg";
import PLENKA from "./images/0b309598837c095d8222b5c00189e2fe.jpg";
import PITANIE from "./images/1c87408d46d08464a9d16106c938cd85.jpeg";
import PACK_FASTFOOD from "./images/22c60eb64c4eb54177d7cafd53714d7d.jpg";
import SETKA from "./images/40b2b134c1aa0f0530116f06f8fb309e.jpg";
import ODEJDA from "./images/4629084d52074f181c39415c4331e72d.jpg";
import SKATERT from "./images/46e7989ce960115ae6b2bf5705972e1b.jpg";
import SHARI from "./images/5.png";
import FLOWER from "./images/6.png";
import HIMIYA from "./images/677678b9bec4d406c912a455992fd23d.jpg";
import AUTOTOVARY from "./images/7.png";
import PAKETI from "./images/70f551d8c4d78808abed2bbe469bed0b.jpg";
import PET_TARA from "./images/8a870d6f4c68a454c5d726437b0fd0f6.jpg";
import NG from "./images/8f84f9d311de24313c9fabd1980bb5a8.jpg";
import SANITAR from "./images/95dd30fd4566659a90baa1b933f13d13.jpg";
import MED from "./images/97e04be1b43c0d04363615567638b893.jpg";
import STEKLO_TARA from "./images/c34c666f56d61f684f809d77f03a4833.jpg";
import IGRUSHKI from "./images/cbef0c6154d645888f9b6efde8e8578b.jpg";
import LOTKI from "./images/d313b09a8c4b7e8895428bfaa8db6031.jpg";
import HOZ from "./images/d387dd945fa30c848654f3ddb6f55d3c.jpg";
import POSUDA from "./images/e5e6bf139718d0c154831c3f246a40ee.jpg";
import KANZ from "./images/fda94458d389575a68a9748973c95e53.jpg";
import { StyledCategory, StyledCategoryImage } from "./StyledCategory";
import { SubCategory } from "./SubCategory";
import { Title } from "./Title";
import { useAppSelector } from "@/hooks/redux";
import { cssIsActive } from "@/styles/utils/Utils";
import { CategoryItemPropsType, ICategoryTreeItem } from "@/types";
import { Button } from "@/ui/Button/Button";
import { cssButtonBackward } from "@/ui/Button/StyledButton";
import { EntityImage } from "@/ui/EntityImage/EntityImage";
const COMPARE_IMAGES = {
  "0e5e9ca0-345c-11e2-a1fb-742f68689961": PET_TARA.src,
  "64bb00d1-e257-11e7-be89-6c3be5236808": PACK_FASTFOOD.src,
  "fc802758-8cea-11e9-df8a-ac1f6b855a52": STEKLO_TARA.src,
  "453db070-230a-11e2-871e-742f68689961": PLENKA.src,
  "0e5e9cdb-345c-11e2-a1fb-742f68689961": SKATERT.src,
  "5dfe2d6b-2288-11e2-9e31-742f68689961": LOTKI.src,
  "37832fef-1dd8-11e2-bf75-742f68689961": POSUDA.src,
  "1fe15a20-620b-11e2-b522-742f68689961": PAKETI.src,
  "a2e96e34-8cf4-11e9-df8a-ac1f6b855a52": MED.src,
  "f44fbc43-21c6-11e2-b10f-38607704c280": HIMIYA.src,
  "0b694f44-4f9a-11e9-9e9a-ac1f6b855a52": SANITAR.src,
  "453db073-230a-11e2-871e-742f68689961": HOZ.src,
  "4319fbe8-5649-11e2-b0e7-38607704c280": KANZ.src,
  "fca0b28d-f4f7-11e7-be8b-6c3be5236808": BANYA.src,
  "4b51ac63-6c4d-11e3-891a-38607704c280": PITANIE.src,
  "b6c930ef-c92a-11e3-9291-742f68689961": IGRUSHKI.src,
  "f03776b0-355c-11eb-f49d-ac1f6b855a52": NG.src,
  "d1caaa8e-99e0-11e3-b23a-742f68689961": ODEJDA.src,
  "4daf8071-9465-11e2-ac6a-742f68689961": SETKA.src,
  "902424fe-6d36-11ec-c597-ac1f6b855a52": SHARI.src,
  "cd0bc9a0-18ef-11ea-8492-ac1f6b855a52": FLOWER.src,
  "2d62676e-23c7-11ea-eb9d-ac1f6b855a52": AUTOTOVARY.src
};
export type CategoryPropsType = {
  category: ICategoryTreeItem;
} & CategoryItemPropsType & HTMLAttributes<HTMLElement>;
export const Category: FC<CategoryPropsType> = memo(({
  category,
  currentId,
  updateCurrentCategoryId
}) => {
  const isIncludes = useAppSelector(({
    categories
  }) => (categories.categories?.treeCompared ?? {})[category.uuid || ""].some(item => item.uuid === currentId));
  const isShowCategory = (category?.product_qty || 0) > 0 && (currentId === null || currentId === category.uuid || isIncludes);
  if (!isShowCategory) {
    return null;
  }
  const isChildsContains = Object.keys(category.children || {}).length > 0;
  const resetCurrentId = () => {
    if (updateCurrentCategoryId) {
      updateCurrentCategoryId({
        id: null
      });
    }
  };
  const updateCurrentIdHandle = () => {
    if (updateCurrentCategoryId) {
      updateCurrentCategoryId({
        id: category.uuid || null,
        parent: category.parent
      });
    }
  };
  const categoriesChilds = Object.entries(category.children).map(([, child]) => child);
  return <StyledCategory className={currentId === category.uuid ? cssIsActive : ""} data-id={category.uuid} data-weight={category.weight || 0}>
        {currentId === category.uuid && <>
            <Button variant={"small"} icon={"ArrowLeft"} isHiddenBg onClick={resetCurrentId} className={cssButtonBackward}>
              Назад
            </Button>
          </>}

        {category.parent !== undefined && category.parent === 0 && <>
            <StyledCategoryImage>
              <EntityImage imagePath={!!category.image && category.image.length > 0 ? category.image : COMPARE_IMAGES[category.uuid || ""] || undefined} imageAlt={category.name || ""} width={80} height={80} layout={"intrinsic"} objectFit={"cover"} isStatic={!!category.image && category.image.length > 0 ? false : !!COMPARE_IMAGES[category.uuid || ""]} />
            </StyledCategoryImage>
          </>}

        <Title category={category} parent={category?.parent} updateCurrentId={updateCurrentIdHandle} currentId={currentId} isChildrenContains={isChildsContains} path={category.alias || ""} />

        <SubCategory currentId={currentId} categories={categoriesChilds} />
      </StyledCategory>;
});
Category.displayName = "Category";